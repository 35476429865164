import newsDetail from '@/views/urbanManageNews/newsDetail.vue'
import API from '@/api/index'
const { getRegulationsDetails } = API

export default {
  mixins: [newsDetail],
  data () {
    return {
      title: '政策法规'
    }
  },
  methods: {
    async getDiscloseDetail () {
      let loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true
      })
      let res = await getRegulationsDetails({ url: '/ps/policy/' + this.$route.params.id }).catch(() => {
        loading.clear()
      })

      loading.clear()

      if (res.success) {
        this.detail = {
          newsTitle: res.data.policyTitle,
          newsPromulgator: res.data.newsPromulgator,
          publishTime: res.data.publishTime,
          content: res.data.newsContent
        }
      } else {
        this.$toast(res.message)
      }
    }
  }
}
